<template>
  <div class="career">
    <div class="banner mb-4 mb-sm-6 mb-md-8 mb-lg-10" data-aos="zoom-in">  
      <div class="banner__info pa-3 text-center">
        <div class="text-h4 text-md-h3 text-lg-h2 mb-1">Career</div>
        <div style="letter-spacing: .5px; font-weight: 300; font-size: 13px;">Working at Numanchi means dealing with the top technologies in one of the most appealing fields in todays challenging industry environment. <br> At Numanchi you will enjoy a dynamic, diverse and innovative work environment, with great team spirit. These are our current vacant positions:</div>  
      </div>  
      <img src="https://wallpaperaccess.com/full/2675398.jpg" alt="Career Image">
    </div>  
    <v-container>
      <div class="display-1" style="line-height: 1;" data-aos="zoom-in">Available Jobs</div> 
      <div class="jobs d-flex flex-column mt-3 mt-sm-4" style="grid-gap: 12px;">
        <div class="job pa-4" v-for="(job, j) in jobs" :key="j" data-aos="zoom-in">
          <div class="d-flex flex-column" style="grid-gap: 4px;">
            <div class="job__title">{{ job.position }}</div>
            <div class="job__desc">{{ job.description }}</div>
          </div>
          <v-icon size="16" color="primary" v-text="'mdi-chevron-right'" />
        </div>  
      </div>
    </v-container>
    <div class="display-1 text-center px-3 mt-4 mt-sm-6 mt-md-8 mt-lg-10 mb-3" data-aos="zoom-in">Life At Numanchi</div>
    <div class="life__at">
      <div v-for="(img, i) in images" :key="i" class="life__at__img" data-aos="zoom-in">
        <img :src="img.image" :alt="img.title">
      </div>  
    </div>
  </div>  
</template>

<script>
export default {
  computed: {
    jobs() { return this.$store.state.jobs },
    images() { return this.$store.state.images }
  } 
}
</script>

<style lang="scss">
.job {
  box-shadow: 0 0 20px 0 #0e1f5813;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  grid-gap: 4px;
  align-items: center;
  justify-content: space-between;
  .job__title {
    line-height: 1.3;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: .5px;
  }
  .job__desc {
    line-height: 1.3;
    font-size: 13px;
  }
}
</style>